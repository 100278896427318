<template>
  <div>
    <div v-if="!isInMultipleMode">
      <Subheader
        icon="mdi-calendar-text"
        title="Veranstaltungsdetails"
      ></Subheader>
      <div class="mt-7">
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="7">
                  <v-text-field
                    v-model.trim="details.title"
                    :counter="35"
                    label="Veranstaltungstitel"
                    required
                    :rules="[rules.required]"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="5">
                  <v-select
                    v-model.trim="details.type"
                    :items="eventTypes"
                    item-text="title"
                    item-value="meta.id"
                    required
                    return-object
                    :rules="[rules.required]"
                    outlined
                    label="Veranstaltungsart"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="7">
                  <v-text-field
                    v-model.trim="details.location.name"
                    label="Veranstaltungsort"
                    prepend-inner-icon="mdi-map-marker"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="details.duration.start.date"
                    type="date"
                    label="Startdatum"
                    :persistent-hint="isInTemplateMode"
                    :hint="
                      isInTemplateMode
                        ? 'Wert für Vorlage nicht einsetzbar'
                        : 'DD.MM.YYYY'
                    "
                    outlined
                    :disabled="isInTemplateMode"
                    :rules="isInTemplateMode ? '' : [rules.required]"
                    required
                    prepend-inner-icon="mdi-calendar-start"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="details.duration.start.time"
                    label="Startzeit"
                    hint="HH:MM"
                    outlined
                    :rules="isInTemplateMode ? '' : [rules.required]"
                    type="time"
                    prepend-inner-icon="mdi-clock-start"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-checkbox
                    v-model="details.duration.start.isOpen"
                    label="Startzeit noch offen"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="details.duration.end.date"
                    type="date"
                    label="Enddatum"
                    :persistent-hint="isInTemplateMode"
                    :hint="
                      isInTemplateMode
                        ? 'Wert für Vorlage nicht einsetzbar'
                        : 'DD.MM.YYYY'
                    "
                    required
                    :disabled="isInTemplateMode"
                    :rules="isInTemplateMode ? '' : [rules.required]"
                    outlined
                    prepend-inner-icon="mdi-calendar-end"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    v-model="details.duration.end.time"
                    label="Endzeit"
                    hint="HH:MM"
                    outlined
                    type="time"
                    :rules="[rules.required]"
                    prepend-inner-icon="mdi-clock-end"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5">
                  <v-checkbox
                    v-model="details.duration.end.isOpen"
                    label="Endzeit noch offen"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model.trim="details.description"
                    counter
                    clearable
                    rows="3"
                    no-resize
                    auto-grow
                    label="Beschreibung"
                    outlined
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-combobox
                    v-model="details.requiredPPE"
                    :items="
                      $store.state.organization.activeOrganization.config
                        .scheduling.ppeTemplates
                    "
                    label="Einsatzkleidung/PSA auswählen oder hinzufügen"
                    multiple
                    outlined
                    clearable
                    deletable-chips
                    auto-select-first
                    :search-input.sync="search"
                    hide-selected
                    small-chips
                    hide-details="auto"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Keine Einträge für "<strong>{{ search }}</strong
                            >" vorhanden. Mit <kbd>enter</kbd> bestätigen und
                            hinzufügen
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template></v-combobox
                  >
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="details.tasks"
                    :items="
                      $store.state.organization.activeOrganization.config
                        .scheduling.taskTemplates
                    "
                    outlined
                    multiple
                    clearable
                    hide-details="auto"
                    item-text="title"
                    small-chips
                    deletable-chips
                    return-object
                    label="Aufgaben auswählen"
                    class="mb-4"
                  ></v-select> </v-col
                ><v-col cols="12" sm="12" md="12">
                  <v-select
                    v-model="details.units"
                    :items="units"
                    outlined
                    multiple
                    clearable
                    hide-details="auto"
                    item-text="displayTitle"
                    item-value="crewCombination.id"
                    small-chips
                    deletable-chips
                    return-object
                    label="Einsatzmittel auswählen"
                    class="mb-4"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="7">
            <v-text-field
              v-model.trim="details.title"
              :counter="35"
              label="Veranstaltungstitel"
              required
              :rules="[rules.required]"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="5">
            <v-select
              v-model.trim="details.type"
              :items="eventTypes"
              item-text="title"
              item-value="meta.id"
              required
              return-object
              :rules="[rules.required]"
              outlined
              label="Veranstaltungsart"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" md="7">
            <v-text-field
              v-model.trim="details.location.name"
              label="Veranstaltungsort"
              prepend-inner-icon="mdi-map-marker"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="details.duration.start.date"
              type="date"
              label="Startdatum"
              :persistent-hint="isInTemplateMode"
              :hint="
                isInTemplateMode
                  ? 'Wert für Vorlage nicht einsetzbar'
                  : 'DD.MM.YYYY'
              "
              outlined
              :disabled="isInTemplateMode"
              :rules="
                isInTemplateMode ? '' : [rules.required, rules.startBeforeEnd]
              "
              required
              prepend-inner-icon="mdi-calendar-start"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="details.duration.start.time"
              label="Startzeit"
              hint="HH:MM"
              outlined
              :rules="
                isInTemplateMode ? '' : [rules.required, rules.startBeforeEnd]
              "
              type="time"
              prepend-inner-icon="mdi-clock-start"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <v-checkbox
              v-model="details.duration.start.isOpen"
              label="Startzeit noch offen"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="details.duration.end.date"
              type="date"
              label="Enddatum"
              :persistent-hint="isInTemplateMode"
              :hint="
                isInTemplateMode
                  ? 'Wert für Vorlage nicht einsetzbar'
                  : 'DD.MM.YYYY'
              "
              required
              :disabled="isInTemplateMode"
              :rules="
                isInTemplateMode ? '' : [rules.required, rules.startBeforeEnd]
              "
              outlined
              prepend-inner-icon="mdi-calendar-end"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="details.duration.end.time"
              label="Endzeit"
              hint="HH:MM"
              outlined
              type="time"
              :rules="
                isInTemplateMode ? '' : [rules.required, rules.startBeforeEnd]
              "
              prepend-inner-icon="mdi-clock-end"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5">
            <v-checkbox
              v-model="details.duration.end.isOpen"
              label="Endzeit noch offen"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-textarea
              v-model.trim="details.description"
              counter
              clearable
              rows="4"
              no-resize
              auto-grow
              label="Beschreibung"
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-combobox
              v-model="details.requiredPPE"
              :items="
                $store.state.organization.activeOrganization.config.scheduling
                  .ppeTemplates
              "
              label="Einsatzkleidung/PSA auswählen oder hinzufügen"
              multiple
              outlined
              clearable
              deletable-chips
              auto-select-first
              :search-input.sync="search"
              hide-selected
              small-chips
              hide-details="auto"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Keine Einträge für "<strong>{{ search }}</strong
                      >" vorhanden. Mit <kbd>enter</kbd> bestätigen und
                      hinzufügen
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template></v-combobox
            >
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-select
              v-model="details.tasks"
              :items="
                $store.state.organization.activeOrganization.config.scheduling
                  .taskTemplates
              "
              outlined
              multiple
              clearable
              hide-details="auto"
              item-text="title"
              small-chips
              deletable-chips
              return-object
              label="Aufgaben auswählen"
              class="mb-4"
            ></v-select> </v-col
          ><v-col cols="12" sm="12" md="12">
            <v-select
              v-model="details.units"
              :items="units"
              outlined
              multiple
              clearable
              hide-details="auto"
              item-text="displayTitle"
              item-value="crewCombination.id"
              small-chips
              deletable-chips
              return-object
              label="Einsatzmittel auswählen"
              class="mb-4"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { SCHEDULING, AVAILABILITY } from "@/store/modules.js";
import { GET_EVENT_TYPES, GET_UNITS } from "@/store/action-types.js";
import Subheader from "@/components/_systemwide/Subheader.vue";

export default {
  name: "event-manage-details",
  components: {
    Subheader,
  },
  props: {
    isInTemplateMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInMultipleMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    details: {},
  },
  model: {
    prop: "details",
    event: "detailsChange",
  },
  data() {
    return {
      search: "",
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        startBeforeEnd: () =>
          (!this.fieldsAreFilled &&
            this.convertToTimestamp(
              this.details.duration.start.date,
              this.details.duration.start.time
            ).valueOf() <
              this.convertToTimestamp(
                this.details.duration.end.date,
                this.details.duration.end.time
              ).valueOf()) ||
          "Start muss vor Ende liegen",
      },
    };
  },
  watch: {
    "details.duration.start.date": function (newValue) {
      if (newValue && this.details.duration.end.date !== newValue) {
        this.details.duration.end.date = newValue;
      }
    },
  },
  computed: {
    detailsLocal: {
      get: function () {
        return this.details;
      },
      set: function (value) {
        this.$emit("detailsChange", value);
      },
    },
    eventTypes() {
      return this.$store.state.scheduling.eventTypes.map((item) => {
        return {
          title: item.title,
          shortTitle: item.shortTitle,
          color: item.color,
          description: item.description,
          dutyTypeId: item.dutyType.id,
          id: item.meta.id,
        };
      });
    },
    units() {
      const units = this.$store.state.availability.units;
      const sortedUnits = units.sort((a, b) => a.priority - b.priority);
      const result = [];

      sortedUnits.forEach((unit) => {
        if (unit.crewCombinations && unit.crewCombinations.length > 0) {
          unit.crewCombinations.forEach((crewCombination) => {
            result.push({
              displayTitle: unit.title + " (" + crewCombination.title + ")",
              title: unit.title,
              crewCombination: {
                id: crewCombination.id,
                title: crewCombination.title,
              },
              id: unit.meta.id,
              disabled: false,
            });
          });
        } else {
          result.push({
            displayTitle: unit.title,
            title: unit.title,
            crewCombination: {
              id: null,
              title: null,
            },
            id: unit.meta.id,
            disabled: true,
          });
        }
      });
      return result;
    },
    fieldsAreFilled() {
      if (
        !this.details.duration.start.date === "" &&
        !this.details.duration.start.time === "" &&
        !this.details.duration.end.date === "" &&
        !this.details.duration.end.time === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${SCHEDULING}${GET_EVENT_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${AVAILABILITY}${GET_UNITS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>
