<template>
  <div>
    <NavigationBar
      titel="Mehrere Veranstaltungen erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <div>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-card>
                        <!-- <v-card-actions> -->
                        <v-col>
                          <v-btn
                            depressed
                            text
                            :disabled="documents.length < 2"
                            @click="sortAllDocuments()"
                            ><v-icon left>mdi-sort-calendar-ascending</v-icon
                            >Sortieren nach Startzeitpunkt</v-btn
                          >
                          <v-btn
                            depressed
                            text
                            :disabled="documents.length === 0"
                            @click="validateAllDocuments()"
                            ><v-icon left>mdi-check-all</v-icon>Datensätze
                            validieren</v-btn
                          >
                          <v-btn
                            depressed
                            text
                            color="error"
                            :disabled="documents.length === 0"
                            @click="removeAllDocuments()"
                            ><v-icon left>mdi-delete</v-icon>Alle
                            entfernen</v-btn
                          >
                        </v-col>
                        <!-- </v-card-actions> -->
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-data-iterator
                        :items="documents"
                        no-data-text=""
                        hide-default-footer
                        disable-pagination
                      >
                        <template v-slot:default="props">
                          <v-row>
                            <v-col
                              v-for="(document, index) in props.items"
                              :key="index"
                              cols="12"
                            >
                              <v-card>
                                <v-card-text>
                                  <v-form
                                    @submit.prevent="createItems"
                                    ref="document"
                                    v-model="document.isValid"
                                  >
                                    <event-manage-details
                                      v-model="props.items[index]"
                                      :isInMultipleMode="true"
                                    ></event-manage-details>
                                  </v-form>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                  <v-btn
                                    text
                                    class="hidden-md-and-down"
                                    @click="duplicateDocument(document)"
                                  >
                                    <v-icon left>mdi-content-duplicate</v-icon
                                    >Duplizieren
                                  </v-btn>
                                  <v-btn
                                    icon
                                    class="hidden-lg-and-up"
                                    @click="duplicateDocument(document)"
                                  >
                                    <v-icon>mdi-content-duplicate</v-icon>
                                  </v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="error"
                                    text
                                    class="hidden-md-and-down"
                                    @click="removeDocument(document)"
                                  >
                                    <v-icon left>mdi-delete</v-icon>Entfernen
                                  </v-btn>
                                  <v-btn
                                    color="error"
                                    icon
                                    text
                                    class="hidden-lg-and-up"
                                    @click="removeDocument(document)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </v-card-actions>
                              </v-card></v-col
                            >
                          </v-row>
                        </template>
                      </v-data-iterator>
                    </v-col>
                    <v-col cols="12" align="center">
                      <v-btn color="success" @click="addDocument()">
                        <v-icon left>mdi-calendar-plus</v-icon>Hinzufügen
                      </v-btn>
                      <v-menu
                        v-model="helpers.templateSelector.model"
                        :close-on-click="false"
                        :close-on-content-click="false"
                        :nudge-width="150"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="success"
                            class="ml-4"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left>mdi-book-arrow-right</v-icon>Hinzufügen
                            aus Vorlage
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form
                            @submit.prevent="addDocumentsFromTemplate"
                            ref="templateSelector"
                            v-model="helpers.templateSelector.isValid"
                          >
                            <v-list>
                              <v-list-item>
                                <v-select
                                  v-model="
                                    helpers.templateSelector.nextTemplate
                                  "
                                  :items="templates"
                                  item-text="template.title"
                                  item-value="meta.id"
                                  class="pt-1"
                                  :rules="[rules.required]"
                                  return-object
                                  label="Vorlage auswählen"
                                  no-data-text="Keine Vorlagen vorhanden"
                                  outlined
                                >
                                </v-select>
                              </v-list-item>
                              <!-- <v-list-item>
                                <v-text-field
                                  v-model.number="
                                    helpers.templateSelector.nextTemplateCount
                                  "
                                  outlined
                                  :rules="[
                                    rules.required,
                                    rules.min,
                                    rules.max10,
                                  ]"
                                  type="number"
                                  label="Anzahl"
                                >
                                </v-text-field>
                              </v-list-item> -->
                            </v-list>

                            <v-card-actions class="mt-n4">
                              <v-btn
                                text
                                @click="helpers.templateSelector.model = false"
                              >
                                Abbrechen
                              </v-btn>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="primary"
                                text
                                :disabled="!helpers.templateSelector.isValid"
                                @click="addDocumentsFromTemplate()"
                              >
                                Hinzufügen aus Vorlage
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { CREATE_EVENTS, GET_EVENT_TEMPLATES } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import EventManageDetails from "@/components/scheduling/EventManageDetails.vue";

export default {
  name: "scheduling-event-new-multi",
  components: {
    NavigationBar,
    EventManageDetails,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItems,
        },
      ],
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        min: (value) =>
          /^[+]?\d+([.]\d+)?$/.test(value) || "Muss positive Zahl sein.",
        max10: (value) =>
          /^([1-9]|10)$/.test(value) || "Darf nicht größer als 10 sein.",
      },

      documents: [],

      helpers: {
        templateSelector: {
          isValid: false,
          model: false,
          nextTemplate: "",
          nextTemplateCount: 1,
        },
      },
    };
  },
  computed: {
    templates() {
      return this.$store.state.scheduling.eventTemplates;
    },
    documentsAreValid() {
      if (
        this.documents.length === 0 ||
        this.documents.some((document) => document.isValid === false)
      ) {
        return false;
      } else {
        return true;
      }
    },
    documentsPrepared() {
      const result = this.documents.map((item) => {
        const container = {};

        container.title = item.title;
        container.description = item.description;
        container.type = {
          id: item.type.id,
          title: item.type.title,
          shortTitle: item.type.shortTitle,
          description: item.type.description,
          color: item.type.color,
          dutyTypeId: item.type.dutyTypeId,
        };
        container.location = { name: item.location.name };
        container.requiredPPE = item.requiredPPE;
        container.tasks = item.tasks;
        container.units = item.units;
        container.duration = {
          start: {
            weekday: this.getISODay(
              this.convertToTimestamp(
                item.duration.start.date,
                item.duration.start.time
              )
            ),
            timestamp: this.convertToTimestamp(
              item.duration.start.date,
              item.duration.start.time
            ),
            isOpen: item.duration.start.isOpen,
          },
          end: {
            weekday: this.getISODay(
              this.convertToTimestamp(
                item.duration.end.date,
                item.duration.end.time
              )
            ),
            timestamp: this.convertToTimestamp(
              item.duration.end.date,
              item.duration.end.time
            ),
            isOpen: item.duration.end.isOpen,
          },
          inMinutes: this.calculateDuration(
            this.convertToTimestamp(
              item.duration.start.date,
              item.duration.start.time
            ),
            this.convertToTimestamp(
              item.duration.end.date,
              item.duration.end.time
            ),
            "minutes"
          ),
          inHours: this.calculateDuration(
            this.convertToTimestamp(
              item.duration.start.date,
              item.duration.start.time
            ),
            this.convertToTimestamp(
              item.duration.end.date,
              item.duration.end.time
            ),
            "hours"
          ),
        };
        container.details = item.details;
        container.config = {
          organizer: {
            isAppointed: item.config.organizer.isAppointed,
            hasAdditionalPermissions:
              item.config.organizer.hasAdditionalPermissions,
            description: item.config.organizer.description,
            personIds: item.config.organizer.personIds,
            personData: item.config.organizer.personData,
          },
          attendance: {
            isMandatory: item.config.attendance.isMandatory,
            isLimited: item.config.attendance.isLimited,
            description: item.config.attendance.description,
            minAttendees: item.config.attendance.minAttendees,
            maxAttendees: item.config.attendance.maxAttendees,
            allowedAttendeesIds: item.config.attendance.allowedAttendeesIds,
            allowedPersons: item.config.attendance.allowedPersons,
            allowedUserGroups: item.config.attendance.allowedUserGroups,
          },
          responses: {
            areBinding: item.config.responses.areBinding,
            areLocked: item.config.responses.areLocked,
            deadline: {
              isCustom: item.config.responses.deadline.isCustom,
              timestamp: container.duration.start.timestamp,
            },
          },
        };
        if (container.config.responses.deadline.isCustom) {
          container.config.responses.deadline.timestamp =
            this.calculateDeadline(
              item.config.responses.deadline.inMinutes,
              item.duration.start.date,
              item.duration.start.time
            );
        }

        return container;
      });

      return result;
    },
  },
  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(
      "Willst Du die Seite wirklich verlassen? Nicht gespeicherte Änderungen gehen verloren."
    );
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  mounted() {
    this.documents = [];
  },
  methods: {
    initialize() {
      this.helpers.templateSelector.loading = true;
      this.$store
        .dispatch(`${SCHEDULING}${GET_EVENT_TEMPLATES}`, {
          organizationId: this.$route.params.organizationId,
        })
        .then(() => {
          this.helpers.templateSelector.loading = false;
        });
    },
    calculateDuration(startTimestamp, endTimestamp, unit = "minutes") {
      const startTime = startTimestamp.toDate();
      const endTime = endTimestamp.toDate();

      const durationInMilliseconds = endTime - startTime;

      if (unit === "minutes") {
        // Calculate and return duration in minutes
        return Math.floor(durationInMilliseconds / (60 * 1000));
      } else if (unit === "hours") {
        // Calculate and return duration in hours
        return durationInMilliseconds / (60 * 60 * 1000);
      } else {
        // Invalid unit specified
        return null;
      }
    },
    getISODay(timestamp) {
      // Ensure timestamp is a valid Firebase Timestamp
      if (!timestamp || !timestamp.toDate) {
        return null;
      }

      // Get a JavaScript Date object from the Firebase Timestamp
      const date = timestamp.toDate();

      // Get the ISO day (1 for Monday, 7 for Sunday)
      let isoDay = date.getUTCDay();

      // Adjust Sunday (0) to 7 to match ISO 8601 standard
      if (isoDay === 0) {
        isoDay = 7;
      }

      return isoDay;
    },
    addDocument() {
      if (this.documents.length > 50) {
        alert(
          "Es können nicht mehr als 50 Datensätze gleichzeitig hinzugefügt werden."
        );
      } else {
        var data = {
          title: null,
          description: null,
          type: {
            id: null,
            title: null,
            shortTitle: null,
            color: null,
            description: null,
            dutyTypeId: null,
          },
          location: {
            name: null,
          },
          requiredPPE: [],
          tasks: [],
          units: [],
          duration: {
            start: {
              date: null,
              time: null,
              timestamp: null,
              isOpen: false,
            },
            end: {
              date: null,
              time: null,
              timestamp: null,
              isOpen: false,
            },
          },
          details: [],
          config: {
            organizer: {
              isAppointed: false,
              hasAdditionalPermissions: false,
              description: "",
              personIds: [],
              personData: [],
            },
            attendance: {
              isMandatory: false,
              isLimited: false,
              description: null,
              minAttendees: null,
              maxAttendees: null,
              allowedAttendeesIds: ["all"],
              allowedPersons: [],
              allowedUserGroups: [
                {
                  title: "Alle",
                  id: "all",
                },
              ],
            },
            responses: {
              areBinding: false,
              areLocked: false,
              deadline: {
                isCustom: false,
                date: null,
                time: null,
                timestamp: null,
              },
            },
          },
        };
        this.documents.push(data);
      }
    },
    addDocumentFromTemplate(template) {
      this.documents.push(template);
    },
    addDocumentsFromTemplate() {
      if (this.documents.length > 50) {
        alert(
          "Es können nicht mehr als 50 Datensätze gleichzeitig hinzugefügt werden."
        );
      } else {
        var template = this.helpers.templateSelector.nextTemplate;
        var data = {
          title: template.data.title,
          description: template.data.description,
          type: {
            id: template.data.type.id,
            title: template.data.type.title,
            shortTitle: template.data.type.shortTitle,
            color: template.data.type.color,
            description: template.data.type.description,
            dutyTypeId: template.data.type.dutyTypeId,
          },
          location: {
            name: template.data.location.name,
          },
          requiredPPE: template.data.requiredPPE,
          tasks: template.data.tasks,
          units: template.data.units,
          duration: {
            start: {
              timestamp: null,
              date: null,
              time: template.data.duration.start.time,
              isOpen: template.data.duration.start.isOpen,
            },
            end: {
              timestamp: null,
              date: null,
              time: template.data.duration.end.time,
              isOpen: template.data.duration.end.isOpen,
            },
          },
          details: template.data.details,
          config: {
            attendance: {
              isMandatory: template.data.config.attendance.isMandatory,
              isLimited: template.data.config.attendance.isLimited,
              description: template.data.config.attendance.description,
              minAttendees: template.data.config.attendance.minAttendees,
              maxAttendees: template.data.config.attendance.maxAttendees,
              allowedAttendeesIds:
                template.data.config.attendance.allowedAttendeesIds,
              allowedPersons: template.data.config.attendance.allowedPersons,
              allowedUserGroups:
                template.data.config.attendance.allowedUserGroups,
            },
            organizer: {
              isAppointed: template.data.config.organizer.isAppointed,
              hasAdditionalPermissions:
                template.data.config.organizer.hasAdditionalPermissions,
              description: template.data.config.organizer.description,
              personIds: template.data.config.organizer.personIds,
              personData: template.data.config.organizer.personData,
            },
            responses: {
              areBinding: template.data.config.responses.areBinding,
              areLocked: template.data.config.responses.areLocked,
              deadline: {
                isCustom: template.data.config.responses.deadline.isCustom,
                inMinutes: template.data.config.responses.deadline.inMinutes,
                timestamp: null,
              },
            },
          },
        };
        let count = this.helpers.templateSelector.nextTemplateCount;
        this.helpers.templateSelector.model = false;
        this.$refs.templateSelector.resetValidation();
        this.helpers.templateSelector.nextTemplateCount = 1;
        this.helpers.templateSelector.nextTemplate = null;
        for (let i = 0; i < count; i++) {
          this.addDocumentFromTemplate(data);
        }
      }
    },
    duplicateDocument(document) {
      if (this.documents.length > 50) {
        alert(
          "Es können nicht mehr als 50 Datensätze gleichzeitig hinzugefügt werden."
        );
      } else {
        var data = {
          title: document.title,
          description: document.description,
          type: {
            id: document.type.id,
            title: document.type.title,
            shortTitle: document.type.shortTitle,
            description: document.type.description,
            color: document.type.color,
            dutyTypeId: document.type.dutyTypeId,
          },
          location: {
            name: document.location.name,
          },
          requiredPPE: document.requiredPPE,
          tasks: document.tasks,
          units: document.units,
          duration: {
            start: {
              timestamp: null,
              time: document.duration.start.time,
              date: document.duration.start.date,
              isOpen: document.duration.start.isOpen,
            },
            end: {
              timestamp: null,
              time: document.duration.end.time,
              date: document.duration.end.date,
              isOpen: document.duration.end.isOpen,
            },
          },
          details: document.details,
          config: {
            attendance: {
              isMandatory: document.config.attendance.isMandatory,
              isLimited: document.config.attendance.isLimited,
              description: document.config.attendance.description,
              minAttendees: document.config.attendance.minAttendees,
              maxAttendees: document.config.attendance.maxAttendees,
              allowedAttendeesIds:
                document.config.attendance.allowedAttendeesIds,
              allowedPersons: document.config.attendance.allowedPersons,
              allowedUserGroups: document.config.attendance.allowedUserGroups,
            },
            organizer: {
              isAppointed: document.config.organizer.isAppointed,
              hasAdditionalPermissions:
                document.config.organizer.hasAdditionalPermissions,
              description: document.config.organizer.description,
              personIds: document.config.organizer.personIds,
              personData: document.config.organizer.personData,
            },
            responses: {
              areBinding: document.config.responses.areBinding,
              areLocked: document.config.responses.areLocked,
              deadline: {
                isCustom: document.config.responses.deadline.isCustom,
                inMinutes: document.config.responses.deadline.inMinutes,
                timestamp: document.config.responses.deadline.timestamp,
              },
            },
          },
        };
        this.documents.push(data);
      }
    },
    removeDocument(document) {
      this.documents.splice(this.documents.indexOf(document), 1);
    },
    removeAllDocuments() {
      var res = confirm(
        "Bist Du sicher, dass Du alle Datensätze entfernen möchtest?"
      );
      if (res == true) {
        this.documents = [];
      }
    },
    validateAllDocuments() {
      let length = this.documents.length;
      for (let i = 0; i < length; i++) {
        this.$refs.document[i].validate();
      }
    },
    sortAllDocuments() {
      this.documents = this.documents.sort(function (a, b) {
        return (
          new Date(a.duration.start.date) - new Date(b.duration.start.date)
        );
      });
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    calculateDeadline(deadlineInMinutes, startDate, startTime) {
      var durationInMin = deadlineInMinutes;
      var MS_PER_MINUTE = 60000;
      var enddate = this.convertToTimestamp(startDate, startTime).toDate();
      var result = Timestamp.fromDate(
        new Date(enddate - durationInMin * MS_PER_MINUTE)
      );
      return result;
    },
    convertToDate(date) {
      return date.getYear() + "-" + date.getMonth() + "-" + date.getDate();
    },
    convertToTime(time) {
      return time.getHours() + ":" + time.getMinutes();
    },
    createItems() {
      this.validateAllDocuments();
      if (this.documents.length == 0) {
        alert("Füge Datensätze hinzu, um Veranstaltungen zu erstellen.");
      } else if (this.documentsAreValid) {
        this.$store
          .dispatch(`${SCHEDULING}${CREATE_EVENTS}`, {
            organizationId: this.$route.params.organizationId,
            events: this.documentsPrepared,
          })
          .catch((error) => {
            console.log(error);
            alert(
              "Fehler: Das hat nicht funktioniert. Versuchen Sie es später erneut oder kontaktieren Sie den Support."
            );
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
