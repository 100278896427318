<template>
  <div>
    <NavigationBar
      titel="Veranstaltungen mittels CSV-Datei erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>

    <v-container>
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-stepper v-model="stepper" vertical>
            <v-stepper-step :complete="stepper > 1" step="1">
              Vorlage auswählen
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-form
                      v-model="helpers.form1IsValid"
                      @submit.prevent="submitData"
                    >
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <div class="subtitle-1">
                            Für den Import von mehreren Veranstaltungen mittels
                            CSV-Datei musst Du eine Vorlage auswählen, die auf
                            alle Datensätze angewendet wird. Wir empfehlen
                            Veranstaltungen manuell anzulegen, die besonderer
                            Konfiguration bedürfen.
                          </div>
                        </v-col>
                        <v-col class="mt-4" cols="12" sm="12" md="6">
                          <v-select
                            :items="[
                              'Feuerwehr',
                              'Hilfsorganisation',
                              'Technisches Hilfswerk (THW)',
                              'Polizei',
                              'Anderes',
                            ]"
                            label="Vorlage auswählen"
                            hint="Die Voreinstellungen der Vorlage werden auf alle importierten Datensätze übertragen."
                            persistent-hint
                            :rules="[rules.required]"
                            required
                            outlined
                            v-model.trim="data.branche"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    right
                    color="primary"
                    :disabled="!helpers.form1IsValid"
                    @click="submitData()"
                  >
                    Weiter
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 2" step="2">
              CSV-Datei für Import hochladen
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <div class="subtitle-1 mb-6">
                          Der Import setzt voraus, dass das Dokument korrekt
                          formatiert ist bzw. bestimmte Vorgaben einhält. Jede
                          Zeile entspricht einem anzulegenden Datensatz, wobei
                          je Wert eine Spalte vorzusehen ist. Du kannst eine
                          Vorlage
                          <a
                            href="https://docs.mira-one.com/"
                            class="text-decoration-none"
                            target="_blank"
                            >hier</a
                          >
                          herunterladen. Dateien des Formats .xls werden nicht
                          akzeptiert, können aber problemlos mit den gängigen
                          Programmen in das .csv-Format umgewandelt werden.
                        </div>
                        <vue-csv-import
                          v-model="csv"
                          :autoMatchFields="true"
                          :autoMatchIgnoreCase="true"
                          :map-fields="[
                            'startdatum',
                            'startzeit',
                            'enddatum',
                            'endzeit',
                          ]"
                        >
                        </vue-csv-import>
                        <div class="my-6">
                          {{ csv }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn depressed plain text @click="stepper = 1">
                    Zurück
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    right
                    color="primary"
                    :disabled="nextBtnDisabled"
                    @click="setCSV()"
                  >
                    Weiter
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-step :complete="stepper > 3" step="3">
              Datensätze prüfen
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-card flat>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <p class="subtitle-1">
                          Der Import ist nahezu abgeschlossen. Bitte prüfe
                          nachfolgend Deine Daten, bevor diese zu Deiner
                          Veranstaltungen hinzugefügt werden!
                        </p>
                        <v-data-table
                          :headers="headers"
                          :items="csvEmpty"
                          fixed-header
                          no-data-text="Keine Daten vorhanden"
                          calculate-widths
                          multi-sort
                          class="elevation-0"
                          style="
                            border: solid 1px;
                            border-color: rgba(158, 158, 158); ;
                          "
                        >
                          <!-- <template slot="items" slot-scope="props">
                        <td class="text-xs-middle">{{ props.item.Ereignisart }}</td>
                        <td class="text-xs-middle">{{ props.item.Name }}</td>
                        <td class="text-xs-middle">{{ props.item.Datum }}</td>
                        <td class="text-xs-middle">{{ props.item.Uhrzeit }}</td>
                        <td class="text-xs-middle">{{ props.item.Ort }}</td>
                        <td class="text-xs-middle">{{ props.item.Beschreibung }}</td>
                        <td class="text-xs-middle">{{ props.item.Link }}</td>
                        <td class="text-xs-middle">{{ props.item.Link }}</td>
                    </template> -->
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    depressed
                    plain
                    :disabled="loading"
                    text
                    @click="stepper = 2"
                  >
                    Zurück
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    depressed
                    right
                    :loading="loading"
                    color="primary"
                    @click="importData()"
                  >
                    Veranstaltungen anlegen
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { CREATE_EVENT } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import VueCsvImport from "@/components/_systemwide/CSVImport.vue";

export default {
  name: "scheduling-event-new-csv",
  components: {
    NavigationBar,
    VueCsvImport,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      stepper: 1,
      loading: false,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },

      helpers: {
        form1IsValid: false,
      },

      data: {
        titel: null,
        beschreibung: null,
        art: {
          id: null,
        },
        ort: {
          bezeichnung: null,
        },
        zeitraum: {
          start: {
            zeitstempel: null,
            zeitIstOffen: false,
          },
          ende: {
            zeitstempel: null,
            zeitIstOffen: false,
          },
        },
        config: {
          anwesenheitspflicht: false,
          rueckmeldefristCustom: null,
        },
      },

      timestamp: {
        start: {
          datum: null,
          zeit: null,
        },
        ende: {
          datum: null,
          zeit: null,
        },
        date: null,
        time: null,
      },

      csv: null,
      csvEmpty: [],
      csvStatic: [
        {
          startdatum: "startdatum",
          startzeit: "startzeit",
          enddatum: "endzeit",
          endzeit: "endzeit",
        },
      ],
      headers: [
        {
          text: "Startdatum",
          align: "left",
          sortable: true,
          value: "startdatum",
        },
        {
          text: "Startzeit",
          align: "left",
          sortable: false,
          value: "startzeit",
        },
        {
          text: "Enddatum",
          align: "left",
          sortable: true,
          value: "enddatum",
        },
        {
          text: "Endzeit",
          align: "left",
          sortable: false,
          value: "endzeit",
        },
      ],
    };
  },
  computed: {
    test() {
      return this.generateZeitstempel(
        this.timestamp.ende.datum,
        this.timestamp.ende.zeit
      );
    },
    nextBtnDisabled() {
      if (this.csv === null) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {},
    submitData() {
      this.stepper = this.stepper + 1;
    },
    setCSV() {
      this.csvEmpty = this.csv.slice();
      this.stepper = this.stepper + 1;
    },
    generateZeitstempel(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input));
    },
    convertToDate(date) {
      return date.getYear() + "-" + date.getMonth() + "-" + date.getDate();
    },
    convertToTime(time) {
      return time.getHours() + ":" + time.getMinutes();
    },
    importData() {
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${SCHEDULING}${CREATE_EVENT}`, {
          organizationId: this.$route.params.organizationId,
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
  },
};
</script>

<style></style>
