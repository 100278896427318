<template>
  <div class="vue-csv-uploader">
    <div class="form">
      <div>
        <div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-file-input
                  v-model="csvFile"
                  outlined
                  chips
                  show-size
                  clearable
                  accept=".csv"
                  label="Datei importieren"
                  placeholder=".csv Dokument hochladen"
                  prepend-icon=""
                  prepend-inner-icon="mdi-file-table"
                  type="file"
                  @click:clear="fileCleared()"
                >
                </v-file-input>
                <slot name="error" v-if="showErrorMessage">
                  <v-alert dense text type="error">
                    Fehler: Dateityp ist ungültig.
                  </v-alert>
                </slot>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div v-if="headers === null && !(csvFile === null)">
          <!-- <slot
            name="hasHeaders"
            :headers="hasHeaders"
            :toggle="toggleHasHeaders"
          >
            <input
              type="checkbox"
              :id="makeId('hasHeaders')"
              :value="hasHeaders"
              @change="toggleHasHeaders"
            />
            <label :for="makeId('hasHeaders')">
              Datei hat Überschriften
            </label>
          </slot> -->
          <v-checkbox
            :id="makeId('hasHeaders')"
            :value="hasHeaders"
            @change="toggleHasHeaders"
            label="Datei enthält Überschriften"
            hint="Die erste Zeile in der Tabelle des importierten Dokuments enthält keinen Datensatz sondern Überschriften."
            persistent-hint
          >
          </v-checkbox>
        </div>
        <div v-if="!(csvFile === null)">
          <!-- :disabled="disabledNextButton" -->
          <v-btn type="submit" depressed @click.prevent="load">
            Datensätze der Datei laden
          </v-btn>
        </div>
      </div>

      <div>
        <div v-if="sample">
          <v-simple-table
            fixed-header
            dense
            style="border: solid 1px; border-color: rgba(158, 158, 158)"
            class="my-5"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Feld</th>
                  <th class="text-left">Spalte in Datei</th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for="item in desserts" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.calories }}</td>
                </tr> -->
                <tr v-for="(field, key) in fieldsToMap" :key="key">
                  <td>
                    <code>{{ field.label }}</code>
                  </td>
                  <td>
                    <!-- <select
                    :name="`csv_uploader_map_${key}`"
                    v-model="map[field.key]"
                    class="my-4"

                  >
                    <option :value="null" v-if="canIgnore">Ignore</option>
                    <option
                      v-for="(column, key) in firstRow"
                      :key="key"
                      :value="key"
                      >{{ column }}</option
                    >
                  </select> -->
                    <v-select
                      :name="`csv_uploader_map_${key}`"
                      v-model="map[field.key]"
                      :items="firstRowOptimized"
                      item-text="title"
                      item-value="column"
                      dense
                      outlined
                      hide-details
                      class="my-4"
                    >
                    </v-select>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="url">
            <!-- <slot name="submit" :submit="submit">
              <input
                type="submit"
                @click.prevent="submit"
              />
            </slot> -->
            <v-btn type="submit" @click.prevent="submit"> Submit </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { drop, every, forEach, get, isArray, map, set } from "lodash";
import axios from "axios";
import Papa from "papaparse";
import mimeTypes from "mime-types";

export default {
  props: {
    value: Array,
    url: {
      type: String,
    },
    mapFields: {
      required: true,
    },
    callback: {
      type: Function,
      default: () => ({}),
    },
    catch: {
      type: Function,
      default: () => ({}),
    },
    finally: {
      type: Function,
      default: () => ({}),
    },
    parseConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    headers: {
      default: null,
    },
    autoMatchFields: {
      type: Boolean,
      default: false,
    },
    autoMatchIgnoreCase: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Boolean,
      default: false,
    },
    fileMimeTypes: {
      type: Array,
      default: () => {
        return [
          "text/csv",
          "text/x-csv",
          "application/vnd.ms-excel",
          "text/plain",
        ];
      },
    },
    tableSelectClass: {
      type: String,
      default: "form-control",
    },
    canIgnore: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    form: {
      csv: null,
    },

    fieldsToMap: [],
    map: {},
    hasHeaders: true,
    csv: null,
    csvFile: null,
    sample: null,
    isValidFileMimeType: false,
    fileSelected: false,
  }),

  created() {
    this.hasHeaders = this.headers;

    if (isArray(this.mapFields)) {
      this.fieldsToMap = map(this.mapFields, (item) => {
        return {
          key: item,
          label: item,
        };
      });
    } else {
      this.fieldsToMap = map(this.mapFields, (label, key) => {
        return {
          key: key,
          label: label,
        };
      });
    }
  },

  methods: {
    fileCleared() {
      this.csv = null;
      this.form.csv = null;
      this.hasHeaders = null;
      this.map = {};
      this.sample = null;
    },
    submit() {
      const _this = this;
      this.form.csv = this.buildMappedCsv();
      this.$emit("input", this.form.csv);

      if (this.url) {
        axios
          .post(this.url, this.form)
          .then((response) => {
            _this.callback(response);
          })
          .catch((response) => {
            _this.catch(response);
          })
          .finally((response) => {
            _this.finally(response);
          });
      } else {
        _this.callback(this.form.csv);
      }
    },
    buildMappedCsv() {
      const _this = this;

      let csv = this.hasHeaders ? drop(this.csv) : this.csv;

      return map(csv, (row) => {
        let newRow = {};

        forEach(_this.map, (column, field) => {
          set(newRow, field, get(row, column));
        });

        return newRow;
      });
    },
    validFileMimeType() {
      let file = this.csvFile;
      const mimeType =
        file.type === "" ? mimeTypes.lookup(file.name) : file.type;

      if (file) {
        this.fileSelected = true;
        this.isValidFileMimeType = this.validation
          ? this.validateMimeType(mimeType)
          : true;
      } else {
        this.isValidFileMimeType = !this.validation;
        this.fileSelected = false;
      }
    },
    validateMimeType(type) {
      return this.fileMimeTypes.indexOf(type) > -1;
    },
    load() {
      const _this = this;

      this.readFile((output) => {
        _this.sample = get(
          Papa.parse(output, { preview: 2, skipEmptyLines: true }),
          "data"
        );
        _this.csv = get(Papa.parse(output, { skipEmptyLines: true }), "data");
      });
    },
    readFile(callback) {
      let file = this.csvFile;

      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function (evt) {
          callback(evt.target.result);
        };
        reader.onerror = function () {};
      }
    },
    toggleHasHeaders() {
      this.hasHeaders = !this.hasHeaders;
    },
    makeId(id) {
      return `${id}${this._uid}`;
    },
  },
  watch: {
    map: {
      deep: true,
      handler: function (newVal) {
        if (!this.url) {
          let hasAllKeys = Array.isArray(this.mapFields)
            ? every(this.mapFields, function (item) {
                // eslint-disable-next-line no-prototype-builtins
                return newVal.hasOwnProperty(item);
              })
            : every(this.mapFields, function (item, key) {
                // eslint-disable-next-line no-prototype-builtins
                return newVal.hasOwnProperty(key);
              });

          if (hasAllKeys) {
            this.submit();
          }
        }
      },
    },
    sample(newVal, oldVal) {
      console.log(oldVal);
      if (this.autoMatchFields) {
        if (newVal !== null) {
          this.fieldsToMap.forEach((field) => {
            newVal[0].forEach((columnName, index) => {
              if (this.autoMatchIgnoreCase === true) {
                if (
                  field.label.toLowerCase().trim() ===
                  columnName.toLowerCase().trim()
                ) {
                  this.$set(this.map, field.key, index);
                }
              } else {
                if (field.label.trim() === columnName.trim()) {
                  this.$set(this.map, field.key, index);
                }
              }
            });
          });
        }
      }
    },
  },
  computed: {
    firstRow() {
      return get(this, "sample.0");
    },
    firstRowOptimized() {
      var input = this.firstRow;
      var output = input.map((val, index) => {
        return {
          title: val,
          column: index,
        };
      });
      return output;
    },
    showErrorMessage() {
      return this.fileSelected && !this.isValidFileMimeType;
    },
    disabledNextButton() {
      return !this.isValidFileMimeType;
    },
  },
};
</script>
