var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NavigationBar',{attrs:{"titel":"Veranstaltungen mittels CSV-Datei erstellen","showBackBtn":"","actions":_vm.actions}}),_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-step',{attrs:{"complete":_vm.stepper > 1,"step":"1"}},[_vm._v(" Vorlage auswählen ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}},model:{value:(_vm.helpers.form1IsValid),callback:function ($$v) {_vm.$set(_vm.helpers, "form1IsValid", $$v)},expression:"helpers.form1IsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"subtitle-1"},[_vm._v(" Für den Import von mehreren Veranstaltungen mittels CSV-Datei musst Du eine Vorlage auswählen, die auf alle Datensätze angewendet wird. Wir empfehlen Veranstaltungen manuell anzulegen, die besonderer Konfiguration bedürfen. ")])]),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{attrs:{"items":[
                            'Feuerwehr',
                            'Hilfsorganisation',
                            'Technisches Hilfswerk (THW)',
                            'Polizei',
                            'Anderes',
                          ],"label":"Vorlage auswählen","hint":"Die Voreinstellungen der Vorlage werden auf alle importierten Datensätze übertragen.","persistent-hint":"","rules":[_vm.rules.required],"required":"","outlined":""},model:{value:(_vm.data.branche),callback:function ($$v) {_vm.$set(_vm.data, "branche", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"data.branche"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","right":"","color":"primary","disabled":!_vm.helpers.form1IsValid},on:{"click":function($event){return _vm.submitData()}}},[_vm._v(" Weiter "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepper > 2,"step":"2"}},[_vm._v(" CSV-Datei für Import hochladen ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"subtitle-1 mb-6"},[_vm._v(" Der Import setzt voraus, dass das Dokument korrekt formatiert ist bzw. bestimmte Vorgaben einhält. Jede Zeile entspricht einem anzulegenden Datensatz, wobei je Wert eine Spalte vorzusehen ist. Du kannst eine Vorlage "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://docs.mira-one.com/","target":"_blank"}},[_vm._v("hier")]),_vm._v(" herunterladen. Dateien des Formats .xls werden nicht akzeptiert, können aber problemlos mit den gängigen Programmen in das .csv-Format umgewandelt werden. ")]),_c('vue-csv-import',{attrs:{"autoMatchFields":true,"autoMatchIgnoreCase":true,"map-fields":[
                          'startdatum',
                          'startzeit',
                          'enddatum',
                          'endzeit',
                        ]},model:{value:(_vm.csv),callback:function ($$v) {_vm.csv=$$v},expression:"csv"}}),_c('div',{staticClass:"my-6"},[_vm._v(" "+_vm._s(_vm.csv)+" ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","plain":"","text":""},on:{"click":function($event){_vm.stepper = 1}}},[_vm._v(" Zurück ")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","right":"","color":"primary","disabled":_vm.nextBtnDisabled},on:{"click":function($event){return _vm.setCSV()}}},[_vm._v(" Weiter "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.stepper > 3,"step":"3"}},[_vm._v(" Datensätze prüfen ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"subtitle-1"},[_vm._v(" Der Import ist nahezu abgeschlossen. Bitte prüfe nachfolgend Deine Daten, bevor diese zu Deiner Veranstaltungen hinzugefügt werden! ")]),_c('v-data-table',{staticClass:"elevation-0",staticStyle:{"border":"solid 1px","border-color":"rgba(158, 158, 158)"},attrs:{"headers":_vm.headers,"items":_vm.csvEmpty,"fixed-header":"","no-data-text":"Keine Daten vorhanden","calculate-widths":"","multi-sort":""}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"depressed":"","plain":"","disabled":_vm.loading,"text":""},on:{"click":function($event){_vm.stepper = 2}}},[_vm._v(" Zurück ")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","right":"","loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.importData()}}},[_vm._v(" Veranstaltungen anlegen "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }